/* eslint-disable @next/next/no-img-element */
import { RefObject, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { Body1, Body2 } from 'stories/elements/Typography/Text'
import theme from 'stories/utils/theme'
import DynamicLink from '../LandingPage/DynamicLink'
import { getCountryConfig } from 'lib/constants/countryconfig'
import { HeaderCategory, getMenuCategories } from 'lib/constants/headerRoutes'
import { parseLocale } from 'lib/constants/localization'
import { useRouter } from 'next/router'
import useMedia from 'lib/hooks/useMedia'
import { deviceSize } from 'lib/constants/GridBreakpoints'
import _useTranslation from 'lib/hooks/_useTranslation'
import { appFeedsRoute, proPlanRoute, subjectsTopicRoute, taxonomyLevelOne } from 'lib/constants/routes'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/reducer'
import HomeIcon from 'assets/app/sidebar/home.svg'
import { SubjectWithTopics } from 'interfaces/Taxonomy'
import Link from 'next/link'
import Arrow from 'assets/icons/menu_arrow_dark.svg'
import Close from 'assets/icons/close.svg'
import Row from 'components/elements/Row'
import { Subject } from 'interfaces/Subject'
import { colorToCssFilter } from 'lib/features/color'
import Logo from 'assets/pro-plan/logo.svg'
import Stack from 'components/elements/Stack'
import { getTaxonomyCountryConfig } from 'lib/constants/taxonomy/taxonomyConfig'
import { useToggleState } from 'lib/hooks/useToggleState'
import { ClientOnly } from 'components/elements/ClientOnly'

const Container = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 10px 0;

    .hidden {
        width: 0px;
        height: 0px;
    }
`

const DropdownMenu = styled.div<{ isHidden?: boolean }>`
    position: absolute;
    top: 70px;
    width: 100%;
    left: 0;
    height: auto;
    padding: 5px 25px;
    box-shadow: rgba(50, 50, 93, 0.15) 0px 13px 27px -5px, rgba(0, 0, 0, 0.1) 0px 8px 16px -8px;
    background-color: ${theme.colors.white};
    z-index: ${theme.zIndex.navBarUserDropdown};
    display: grid;
    grid-template-columns: 1fr;
    animation: showMenu 0.3s ease-in-out forwards;
    @keyframes showMenu {
        0% {
            opacity: 0;
            top: -200px;
        }

        100% {
            opacity: 1;
            top: 70px;
        }
    }
    max-height: 700px;
    overflow-y: auto;
    .grid {
        display: grid;
        grid-template-columns: 1fr 3fr;
    }

    @media (max-width: 750px) {
        padding: 0;
        height: calc(100vh - 60px);
        max-height: calc(100vh - 60px);

        @keyframes showMenu {
            0% {
                opacity: 0;
                left: -200px;
            }

            100% {
                opacity: 1;
                left: 0px;
            }
        }
        top: 60px;
    }

    ${({ isHidden }) =>
        isHidden
            ? css`
                  display: hidden;
              `
            : css`
                  display: grid;
              `}

    .menu {
        display: grid;
        grid-template-columns: 1fr;
        justify-content: start;
        gap: 15px;
        @media (min-width: 1250px) {
            .COMPANY {
                display: none;
            }
        }
    }
    .options {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;
        border-right: 1px solid ${theme.colors.contentBody}28;
        padding: 25px 50px;
        justify-content: start;

        @media (max-width: 900px) {
            border-right: none;
            justify-content: start;
            padding: 15px;
            gap: 10px;
        }
    }

    p {
        user-select: none;
        -o-user-select: none;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
    }
`

const Content = styled.div<{ isShownOnMobile?: boolean }>`
    .container {
        width: fit-content;
        display: grid;
        align-items: start;
        justify-items: start;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 25px;
        row-gap: 35px;
        padding: 25px 50px;
        height: fit-content;
        .arrow {
            transform: rotate(270deg);
            path {
                fill: ${theme.colors.deepSeaBlue};
            }
        }
        a {
            border: 1px solid white;
            padding: 5px 10px;
            border-radius: ${theme.borderRadius.normal};
            :hover {
                background-color: ${theme.colors.violet};
                p {
                    color: ${theme.colors.deepSeaBlue};
                }
            }
        }
        .back-button {
            cursor: pointer;
            @media (min-width: 901px) {
                display: none;
            }
        }
        @media (max-width: 1250px) {
            grid-template-columns: 1fr 1fr;
            row-gap: 35px;
        }
        @media (max-width: 900px) {
            display: none;
            ${({ isShownOnMobile }) =>
                isShownOnMobile
                    ? css`
                          position: absolute;
                          top: 0px;
                          width: 100%;
                          left: 0;
                          height: auto;
                          padding: 15px 25px;
                          box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
                          background-color: ${theme.colors.white};
                          z-index: ${theme.zIndex.navBarUserDropdown};
                          display: flex;
                          justify-content: start;
                          flex-direction: column;
                          animation: showTopicMenu 0.3s ease-in-out forwards;
                          @keyframes showTopicMenu {
                              0% {
                                  opacity: 0;
                              }

                              100% {
                                  opacity: 1;
                              }
                          }
                          height: calc(100vh - 60px);
                          max-height: calc(100vh - 60px);
                          overflow-y: auto;
                      `
                    : ''}
        }
    }
`

const Card = styled.div<{ isSelected?: boolean; isItem?: boolean }>`
    width: 320px;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    height: 65px;
    border-radius: ${theme.borderRadius.normal};
    position: relative;
    cursor: pointer;
    gap: 20px;
    @media (max-width: 900px) {
        height: 50px;
        min-width: 325px;
    }

    img {
        filter: ${colorToCssFilter(theme.colors.deepSeaBlue)};
    }
    .icon {
        svg {
            position: relative;
            left: 1px;
            top: 3px;
            path {
                stroke: ${theme.colors.deepSeaBlue};
            }
        }
    }
    .arrow {
        transform: rotate(90deg);
        position: absolute;
        right: 15px;
        path {
            fill: #0f0f10b2;
        }
    }
    :hover {
        background-color: ${theme.colors.violet};
    }
    ${({ isItem }) =>
        isItem
            ? css`
                  display: grid;
                  grid-template-columns: 28px 1fr;
              `
            : ''}
    ${({ isSelected }) =>
        isSelected
            ? css`
                  background-color: ${theme.colors.violet};
              `
            : ''}
`

const ProRow = styled(Row)`
    align-items: center;
    justify-content: center;
    svg {
        width: 30px;
        height: 18px;
    }
`

const CloseIcon = styled(Close)`
    cursor: pointer;
    position: absolute;
    z-index: ${theme.zIndex.FAB};
    right: 15px;
    top: 15px;
`

interface Props {
    changeMenuState: (show: boolean) => void
    showMenu: boolean
    menuRef: RefObject<HTMLDivElement>
    mobileMenuRef: RefObject<HTMLDivElement>
    hasContentCampaignRunning: boolean
}

const FloatingTaxonomyMenu = ({ changeMenuState, showMenu, menuRef, mobileMenuRef, hasContentCampaignRunning }: Props) => {
    const [selectedSubject, setSelectedSubject] = useState<SubjectWithTopics | null>(null)
    const [showMobileSubjectDialog, toggleMobileSubjectDialog] = useToggleState(false)
    const { locale } = useRouter()
    const taxonomy = getTaxonomyCountryConfig(locale)
    const tablet = useMedia(deviceSize.tablet)
    const sm = useMedia(deviceSize.sm)
    const { t: tCommon } = _useTranslation('common')
    const parsedLocale = parseLocale(locale)
    const countryConfig = getCountryConfig(parsedLocale.countryCode)
    const menuItems = getMenuCategories(countryConfig, tCommon)
    const filteredMenuItems = tablet ? menuItems : menuItems.filter((menu) => menu.category === HeaderCategory.Learning)
    const authenticatedUser = useSelector((state: AppState) => state.user)
    const floatingMenuRef = useRef<HTMLDivElement>(null)
    const hasKnowunityPro = countryConfig?.hasPlusPlan

    useEffect(() => {
        setSelectedSubject(taxonomy[0])
    }, [taxonomy])

    useEffect(() => {
        document.addEventListener('mousedown', handleMouseDown)
        return () => {
            document.removeEventListener('mousedown', handleMouseDown)
        }
    }, [])

    const handleMouseDown = (event: MouseEvent) => {
        const isClickOutsideDialog = !floatingMenuRef.current?.contains(event.target as Node)

        const isClickInsideMenu = menuRef.current?.contains(event.target as Node)
        const isClickInsideMobileMenu = mobileMenuRef.current?.contains(event.target as Node)

        if (isClickInsideMenu || isClickInsideMobileMenu) return

        if (isClickOutsideDialog) {
            changeMenuState(false)
        }
    }

    const handleOptionClicked = () =>
        setTimeout(() => {
            changeMenuState(false)
        }, 250)

    if (!taxonomy || (hasContentCampaignRunning && !tablet)) return null

    return (
        <Container ref={floatingMenuRef}>
            <div className="hidden" hidden={!showMenu}>
                <DropdownMenu>
                    <CloseIcon onClick={() => changeMenuState(false)} />
                    <div className="grid">
                        <div className="options">
                            {!hasContentCampaignRunning ? (
                                <>
                                    {taxonomy.map((subject) => (
                                        <Card
                                            key={subject.id}
                                            isSelected={subject.id === selectedSubject?.id}
                                            onClick={() => {
                                                setSelectedSubject(subject)
                                                toggleMobileSubjectDialog()
                                            }}
                                        >
                                            <img
                                                src={subject.iconUrl}
                                                height={26}
                                                width={33}
                                                className="icon"
                                                alt={subject.name}
                                            />

                                            <Body1
                                                fontWeight={500}
                                                fontFamily="var(--font-inter)"
                                                color={theme.colors.deepSeaBlue}
                                                fontSize={16}
                                            >
                                                {subject.name}
                                            </Body1>
                                            <Arrow className="arrow" />
                                        </Card>
                                    ))}
                                </>
                            ) : null}

                            <div className="menu">
                                {hasKnowunityPro ? (
                                    <div hidden={!sm}>
                                        <Card>
                                            <ProRow>
                                                <div className="icon">
                                                    <Logo />
                                                </div>
                                                <Link href={proPlanRoute} passHref prefetch={false}>
                                                    <Body2
                                                        fontWeight={700}
                                                        fontFamily="var(--font-inter)"
                                                        color={theme.colors.deepSeaBlue}
                                                    >
                                                        Knowunity Pro
                                                    </Body2>
                                                </Link>
                                            </ProRow>
                                            <Arrow className="arrow" />
                                        </Card>
                                    </div>
                                ) : null}
                                {filteredMenuItems.map(({ dropdownItems, category }) => (
                                    <Stack className={category} key={category} gutter={15}>
                                        {dropdownItems.map((item) =>
                                            item.show ? (
                                                <DynamicLink
                                                    href={item.to}
                                                    key={`menu-item-${item.name}`}
                                                    prefetch={false}
                                                    onClick={handleOptionClicked}
                                                >
                                                    <Card isItem>
                                                        <div className="icon">{item.icon}</div>
                                                        <Body1
                                                            fontFamily="var(--font-inter)"
                                                            fontWeight={500}
                                                            color={theme.colors.deepSeaBlue}
                                                        >
                                                            {item.name}
                                                        </Body1>
                                                        <Arrow className="arrow" />
                                                    </Card>
                                                </DynamicLink>
                                            ) : null
                                        )}
                                    </Stack>
                                ))}

                                <ClientOnly>
                                    {authenticatedUser && !hasContentCampaignRunning ? (
                                        <DynamicLink href={appFeedsRoute} prefetch={false} onClick={handleOptionClicked}>
                                            <Card isItem>
                                                <div className="icon">
                                                    <HomeIcon />
                                                </div>
                                                <Body1
                                                    fontFamily="var(--font-inter)"
                                                    fontWeight={500}
                                                    color={theme.colors.deepSeaBlue}
                                                >
                                                    {tCommon('common/navBarHomeLink')}
                                                </Body1>
                                                <Arrow className="arrow" />
                                            </Card>
                                        </DynamicLink>
                                    ) : null}
                                </ClientOnly>
                            </div>
                        </div>

                        <Content isShownOnMobile={!!selectedSubject && showMobileSubjectDialog}>
                            {taxonomy.map((subject) => (
                                <div key={`${subject.id}-topics`} hidden={selectedSubject?.id !== subject.id}>
                                    <div className="container">
                                        <Row onClick={toggleMobileSubjectDialog} className="back-button">
                                            <Arrow className="arrow" />
                                            <Body1
                                                fontWeight={500}
                                                fontFamily="var(--font-inter)"
                                                color={theme.colors.deepSeaBlue}
                                            >
                                                {tCommon('common/menu')}
                                            </Body1>
                                        </Row>
                                        {subject?.topics?.map((topic) => (
                                            <Link
                                                key={topic.uuid}
                                                href={taxonomyLevelOne(topic.slug)}
                                                onClick={handleOptionClicked}
                                            >
                                                <Body1
                                                    fontWeight={500}
                                                    fontFamily="var(--font-inter)"
                                                    color={theme.colors.contentBody}
                                                >
                                                    {topic.name}
                                                </Body1>
                                            </Link>
                                        ))}
                                        {subject ? (
                                            <Link
                                                href={subjectsTopicRoute(subject as unknown as Subject)}
                                                onClick={handleOptionClicked}
                                            >
                                                <Body1
                                                    fontFamily="var(--font-inter)"
                                                    fontWeight={500}
                                                    color={theme.colors.deepSeaBlue}
                                                >
                                                    {tCommon('common/showAllTopicsLabel')}
                                                </Body1>
                                            </Link>
                                        ) : null}
                                    </div>
                                </div>
                            ))}
                        </Content>
                    </div>
                </DropdownMenu>
            </div>
        </Container>
    )
}

export default FloatingTaxonomyMenu
