import theme from '../../utils/theme'
import styled from 'styled-components'

type FontFamily = 'var(--font-poppins)' | 'var(--font-open-sans)' | 'var(--font-inter)'

export interface Props {
    readonly color?: string
    readonly marginBottom?: number
    readonly textAlign?: 'left' | 'right' | 'center'
    readonly fontWeight?: number
    readonly fontSize?: number
    readonly isREM?: boolean
    readonly fontFamily?: FontFamily
}

export const Headline1 = styled.h1<Props>`
    font-family: ${({ fontFamily }) => (fontFamily ? `${fontFamily}` : `var(--font-poppins)`)};
    font-size: ${({ isREM }) => (isREM ? '3rem' : '65px')};
    font-weight: 900;
    line-height: ${({ isREM }) => (isREM ? '3.5rem' : '90px')};
    color: ${(props) => props.color ?? theme.colors.accentBlue};
    margin: 0;
    margin-bottom: ${(props) => props.marginBottom ?? 0}px;
    text-align: ${(props) => props.textAlign ?? 'left'};
    @media (max-width: 750px) {
        font-size: ${({ isREM }) => (isREM ? '1.3rem' : '36px')};
        font-weight: 700;
        line-height: ${({ isREM }) => (isREM ? '2rem' : '54px')};
    }
    @media (min-width: 2048px) {
        font-size: 5rem;
        line-height: ${({ isREM }) => (isREM ? '10rem' : '97.5px')};
    }
`

export const Headline2 = styled.h2<Props>`
    font-family: ${({ fontFamily }) => (fontFamily ? `${fontFamily}` : `var(--font-poppins)`)};
    font-size: ${(props) => props.fontSize ?? 48}px;
    font-weight: ${({ fontWeight: fontWeigth }) => fontWeigth ?? 900};
    line-height: 64px;
    color: ${(props) => props.color ?? theme.colors.accentBlue};
    margin: 0;
    margin-bottom: ${(props) => props.marginBottom ?? 0}px;
    text-align: ${(props) => props.textAlign ?? 'left'};

    @media (max-width: 750px) {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
    }
`

export const Headline3 = styled.h3<Props>`
    font-family: ${({ fontFamily }) => (fontFamily ? `${fontFamily}` : `var(--font-poppins)`)};
    font-size: ${(props) => props.fontSize ?? 36}px;
    font-weight: ${({ fontWeight: fontWeigth }) => fontWeigth ?? 900};
    line-height: ${(props) => (props.fontSize ? props.fontSize * 1.6 : 48)}px;
    color: ${(props) => props.color ?? theme.colors.accentBlue};
    margin: 0;
    margin-bottom: ${(props) => props.marginBottom ?? 0}px;
    text-align: ${(props) => props.textAlign ?? 'left'};

    @media (max-width: 750px) {
        font-size: ${(props) => props.fontSize ?? 20}px;
        font-weight: 700;
        line-height: ${(props) => (props.fontSize ? props.fontSize * 1.6 : 24)}px;
    }
`

export const Headline4 = styled.h4<Props>`
    font-family: ${({ fontFamily }) => (fontFamily ? `${fontFamily}` : `var(--font-poppins)`)};
    font-size: ${(props) => props.fontSize ?? 24}px;
    font-weight: ${({ fontWeight: fontWeigth }) => fontWeigth ?? 700};
    line-height: ${(props) => (props.fontSize ? props.fontSize * 1.6 : 32)}px;
    color: ${(props) => props.color ?? theme.colors.accentBlue};
    margin: 0;
    margin-bottom: ${(props) => props.marginBottom ?? 0}px;
    text-align: ${(props) => props.textAlign ?? 'left'};

    /* Reset */
    text-transform: none;

    @media (max-width: 750px) {
        font-size: 16px;
        font-weight: 700;
        line-height: ${(props) => (props.fontSize ? props.fontSize * 1.6 : 24)}px;
    }
`

export const Headline5 = styled.h5<Props>`
    font-family: ${({ fontFamily }) => (fontFamily ? `${fontFamily}` : `var(--font-poppins)`)};
    font-size: ${(props) => props.fontSize ?? 20}px;
    font-weight: ${({ fontWeight: fontWeigth }) => fontWeigth ?? 700};
    line-height: 28px;
    color: ${(props) => props.color ?? theme.colors.subtitleBlack};
    margin: 0;
    margin-bottom: ${(props) => props.marginBottom ?? 0}px;
    text-align: ${(props) => props.textAlign ?? 'left'};

    /* Reset */
    text-transform: none;

    @media (max-width: 750px) {
        font-size: 15px;
        line-height: 20px;
    }
`

export const Subtitle1 = styled.p<Props>`
    font-family: ${({ fontFamily }) => (fontFamily ? `${fontFamily}` : `var(--font-inter)`)};
    font-size: ${(props) => props.fontSize ?? 16}px;
    font-weight: ${(props) => props.fontWeight ?? 600};
    line-height: 24px;
    color: ${(props) => props.color ?? theme.colors.subtitleBlack};
    margin: 0;
    margin-bottom: ${(props) => props.marginBottom ?? 0}px;
    text-align: ${(props) => props.textAlign ?? 'left'};
`

export const Subtitle2 = styled.p<Props>`
    font-family: ${({ fontFamily }) => (fontFamily ? `${fontFamily}` : `var(--font-open-sans)`)};
    font-size: 12px;
    font-weight: ${(props) => props.fontWeight ?? 700};
    line-height: 16px;
    color: ${(props) => props.color ?? theme.colors.subtitleBlack};
    margin: 0;
    margin-bottom: ${(props) => props.marginBottom ?? 0}px;
    text-align: ${(props) => props.textAlign ?? 'left'};
`

export const Body1 = styled.p<Props>`
    font-family: ${({ fontFamily }) => (fontFamily ? `${fontFamily}` : `var(--font-open-sans)`)};
    font-size: ${(props) => props.fontSize ?? 16}px;
    font-weight: ${(props) => props.fontWeight ?? 600};
    line-height: ${(props) => (props.fontSize ? props.fontSize * 1.4 : 24)}px;
    color: ${(props) => props.color ?? theme.colors.body1Black};
    margin: 0;
    margin-bottom: ${(props) => props.marginBottom ?? 0}px;
    text-align: ${(props) => props.textAlign ?? 'left'};

    & * {
        font-size: 16px;
    }
`

export const Body2 = styled.p<Props>`
    font-family: ${({ fontFamily }) => (fontFamily ? `${fontFamily}` : `var(--font-open-sans)`)};
    font-size: ${({ fontSize }) => fontSize ?? 14}px;
    font-weight: ${(props) => props.fontWeight ?? 600};
    line-height: ${(props) => (props.fontSize ? props.fontSize * 1.4 : 24)}px;
    color: ${(props) => props.color ?? theme.colors.body2Black};
    margin: 0;
    margin-bottom: ${(props) => props.marginBottom ?? 0}px;
    text-align: ${(props) => props.textAlign ?? 'left'};
`

export const Overline = styled.p<Props>`
    font-family: ${({ fontFamily }) => (fontFamily ? `${fontFamily}` : `var(--font-open-sans)`)};
    font-size: ${({ fontSize }) => fontSize ?? 10}px;
    font-weight: ${({ fontWeight: fontWeigth }) => fontWeigth ?? 600};
    line-height: 16px;
    color: ${(props) => props.color ?? theme.colors.captionGray};
    margin: 0;
    margin-bottom: ${(props) => props.marginBottom ?? 0}px;
    text-align: ${(props) => props.textAlign ?? 'left'};
`

export const ButtonText = styled.p<Props>`
    font-family: ${({ fontFamily }) => (fontFamily ? `${fontFamily}` : `var(--font-poppins)`)};
    font-size: ${({ fontSize }) => fontSize ?? 14}px;
    font-weight: ${({ fontWeight: fontWeigth }) => fontWeigth ?? 600};
    line-height: 21px;
    color: ${(props) => props.color ?? theme.colors.captionGray};
    margin: 0;
    margin-bottom: ${(props) => props.marginBottom ?? 0}px;
    text-align: ${(props) => props.textAlign ?? 'left'};
`

export const QuoteText = styled.p<Props>`
    font-family: ${({ fontFamily }) => (fontFamily ? `${fontFamily}` : `var(--font-open-sans)`)};
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    font-style: italic;
    color: ${(props) => props.color ?? theme.colors.subtitleBlack};
    margin: 0;
    margin-bottom: ${(props) => props.marginBottom ?? 0}px;
    text-align: ${(props) => props.textAlign ?? 'left'};
`

export const QuoteText2 = styled.p<Props>`
    font-family: ${({ fontFamily }) => (fontFamily ? `${fontFamily}` : `'var(--font-open-sans)'`)};
    font-size: 14px;
    font-weight: ${({ fontWeight: fontWeigth }) => fontWeigth ?? 600};
    line-height: 24px;
    font-style: italic;
    color: ${(props) => props.color ?? theme.colors.subtitleBlack};
    margin: 0;
    margin-bottom: ${(props) => props.marginBottom ?? 0}px;
    text-align: ${(props) => props.textAlign ?? 'left'};
`

export const Caption = styled.p<Props>`
    font-family: ${({ fontFamily }) => (fontFamily ? `${fontFamily}` : `var(--font-open-sans)`)};
    font-size: ${({ fontSize }) => fontSize ?? 12}px;
    font-style: normal;
    font-weight: ${({ fontWeight: fontWeigth }) => fontWeigth ?? 700};
    line-height: 16px;
    color: ${(props) => props.color ?? theme.colors.subtitleBlack};
    margin: 0;
    margin-bottom: ${(props) => props.marginBottom ?? 0}px;
    text-align: ${(props) => props.textAlign ?? 'left'};
`

export const StandardHeadline = styled.h1<Props>`
    font-family: ${({ fontFamily }) => (fontFamily ? `${fontFamily}` : `var(--font-open-sans)`)};
    font-size: ${({ fontSize }) => fontSize ?? 36}px;
    font-weight: 700;
    line-height: ${(props) => (props.fontSize ? props.fontSize * 1.4 : 48)}px;
    font-weight: ${({ fontWeight: fontWeigth }) => fontWeigth ?? 700};
    color: ${(props) => props.color ?? theme.colors.subtitleBlack};
    margin-bottom: ${(props) => props.marginBottom ?? 0}px;
    text-align: ${(props) => props.textAlign ?? 'left'};
    @media (max-width: 750px) {
        font-size: 20px;
        line-height: 36px;
    }
`

export const StandardSubtitle = styled.h2<Props>`
    font-family: ${({ fontFamily }) => (fontFamily ? `${fontFamily}` : `var(--font-open-sans)`)};
    font-size: ${({ fontSize }) => fontSize ?? 16}px;
    font-weight: ${(props) => props.fontWeight ?? 600};
    line-height: 24px;
    color: ${(props) => props.color ?? theme.colors.body1Black};
    margin: 0;
    margin-bottom: ${(props) => props.marginBottom ?? 0}px;
    text-align: ${(props) => props.textAlign ?? 'left'};
`

export const CustomizableInlineText = styled.span<Props & { hideCursor?: boolean }>`
    all: inherit;
    display: inline;
    color: ${(props) => props.color ?? theme.colors.body1Black};
    font-weight: ${(props) => props.fontWeight ?? 600};
    cursor: ${({ hideCursor }) => (hideCursor ? 'auto' : 'pointer')};
`
