export const AnalyticsEventKnowCreateExpansionExplanationPageView = 'know_create_expansion_explanation_page_view'
export const AnalyticsEventKnowCreateUploadPageView = 'know_create_upload_page_view'
export const AnalyticsEventKnowCreateUploadStart = 'know_create_upload_start'
export const AnalyticsEventKnowCreateUploadComplete = 'know_create_upload_complete'
export const AnalyticsEventKnowCreateUploadButtonClick = 'know_create_upload_button_click'
export const AnalyticsEventKnowCreateDetailsPageView = 'know_create_details_page_view'
export const AnalyticsEventKnowCreateDetailsFormSubmit = 'know_create_details_form_submit'
export const AnalyticsEventKnowCreateDetailsComplete = 'know_create_details_complete'
export const AnalyticsEventKnowCreateExpansionSignUpPageView = 'know_create_expansion_sign_up_page_view'
export const AnalyticsEventKnowCreateExpansionSignUpButtonClick = 'know_create_expansion_sign_up_button_click'
export const AnalyticsEventKnowCreateExpansionSignUpComplete = 'know_create_expansion_sign_up_complete'

export const AnalyticsEventKnowCreate = 'know_create'
export const AnalyticsEventKnowCreateSkipSubmit = 'know_create_skip_submit'
export const AnalyticsEventKnowCreateStart = 'know_create_start'
export const AnalyticsEventKnowCreateSubmit = 'know_create_submit'

export const AnalyticsEventKnowContentView = 'know_content_view'

export const AnalyticsEventKnowConversionDownloadModalRedirect = 'know_conversion_download_modal_redirect'
export const AnalyticsEventKnowLike = 'know_like'
export const AnalyticsEventKnowRemoveLike = 'know_like_remove'
export const AnalyticsEventKnowConversionLike = 'know_conversion_like'
export const AnalyticsEventKnowConversionFollow = 'know_conversion_follow'
export const AnalyticsEventKnowConversionComment = 'know_conversion_comment'
export const AnalyticsEventKnowConversionSave = 'know_conversion_save'
export const AnalyticsEventKnowSaveStart = 'know_save_start'
export const AnalyticsEventKnowSaveToFolder = 'know_save'
export const AnalyticsEventKnowShare = 'know_share'
export const AnalyticsEventKnowShareComplete = 'know_share_complete'
export const AnalyticsEventKnowConversionShare = 'know_conversion_share'
export const AnalyticsEventKnowConversionMobileContent = 'know_conversion_mobile_content'
export const AnalyticsEventKnowShowInformationSection = 'know_show_information_section'
export const AnalyticsEventKnowConversionDialogButtonClick = 'know_conversion_dialog_button_click'
export const AnalyticsEventKnowConversionDialogLoginButtonClick = 'know_conversion_dialog_login_button_click'
export const AnalyticsEventKnowConversionBannerButtonClick = 'know_conversion_banner_button_click'
export const AnalyticsEventKnowConversionContentBannerButtonClick = 'know_conversion_content_banner_button_click'
export const AnalyticsEventKnowConversionMobileFixedBannerButtonClick = 'know_conversion_mobile_fixed_banner_button_click'
export const AnalyticsEventKnowConversionBannerCloseButtonClick = 'know_conversion_banner_close_button_click'
export const AnalyticsEventKnowConversionImageButtonClick = 'know_conversion_image_button_click'
export const AnalyticsEventKnowConversionMobileBannerButtonClick = 'know_conversion_mobile_banner_button_click'
export const AnalyticsEventKnowConversionDialogActionButtonClick = 'know_conversion_dialog_action_button_click'
export const AnalyticsEventKnowConversionDialogExplanationActionButtonClick =
    'know_conversion_dialog_explanation_action_button_click'
export const AnalyticsEventKnowConversionContentModalAlternativeDownloadClick =
    'know_conversion_content_modal_alternative_download_click'
export const AnalyticsEventBreadcrumbClick = 'breadcrumb_click'
export const AnalyticsEventKnowImpression = 'know_impression'
export const AnalyticsEventKnowCommentStartedTyping = 'know_comment_started_typing'
export const AnalyticsEventKnowCommentFieldConversion = 'know_comment_field_conversion'
export const AnalyticsEventKnowMobileDummyMenuClick = 'know_mobile_dummy_menu_click'
export const AnalyticsEventKnowMobileDummyMenuImpression = 'know_mobile_dummy_menu_impression'

export const AnalyticsEventKnowerPayoutRequestFormSubmit = 'knower_payout_request_form_submit'
export const AnalyticsEventKnowerPayoutRequestFormComplete = 'knower_payout_request_form_complete'

export const AnalyticsEventKnowAppPreviewView = 'app_know_preview_view'
export const AnalyticsEventKnowPreviewView = 'know_preview_view'

export const AnalyticsEventKnowOwnView = 'know_own_view'

export const AnalyticsEventKnowerCreate = 'knower_create'

export const AnalyticsEventBecomeKnowerPageView = 'become_knower_page_view'

export const AnalyticsEventLibraryTabImpression = 'library_tab_impression'
export const AnalyticsEventUserFolderView = 'user_folder_view'

export const AnalyticsEventLogin = 'login'

export const AnalyticsEventManageEmailPreferencesUnsubscribe = 'manage_email_preferences_unsubscribe'

export const AnalyticsEventUserCreate = 'user_create'

export const AnalyticsEventTutoringLandingPageTalkToSalesButtonClick = 'tutoring_landing_page_talk_to_sales_button_click'

export const AnalyticsEventPlusPlanSubscriptionCheckoutPaymentMethodSelect =
    'plus_plan_subscription_checkout_payment_method_select'
export const AnalyticsEventPlusPlanSubscriptionCheckoutButtonClick = 'plus_plan_subscription_checkout_button_click'
export const AnalyticsEventPlusPlanSubscriptionCheckoutFailure = 'plus_plan_subscription_checkout_failure'
export const AnalyticsEventPlusPlanSubscriptionCheckoutSuccess = 'plus_plan_subscription_checkout_success'
export const AnalyticsEventPlusPlanSubscriptionSuccessPageView = 'plus_plan_subscription_success_page_view'
export const AnalyticsEventPlusPlanSubscriptionActivationPageView = 'plus_plan_subscription_activation_page_view'
export const AnalyticsEventPlusPlanSubscriptionActivationFailure = 'plus_plan_subscription_activation_failure'
export const AnalyticsEventPlusPlanSubscriptionActivationSuccess = 'plus_plan_subscription_activation_success'

export const AnalyticsEventProfileDelete = 'profile_account_user_delete'

export const AnalyticsEventLandingPageButtonClick = 'landingpage_button_click'
export const AnalyticsEventLandingPagePageView = 'landingpage_page_view'

export const AnalyticsEventCalendlyBooked = 'calendly_booked'

export const AnalyticsEventWebPageNotFound = 'web_page_not_found'

export const AnalyticsEventKnowDemandsReserve = 'know_demand_reserve'
export const AnalyticsEventKnowDemandsCancelReservation = 'know_demand_cancel_reservation'
export const AnalyticsEventKnowDemandsKnowCreate = 'know_demand_know_create'

export const AnalyticsEventNavbarSigninMobileButtonClick = 'navbar_signin_mobile_button_click'

export const AnalyticsEventDownloadPageButtonClick = 'download_page_button_click'

export const AnalyticsEventChatSend = 'chat_send'
export const AnalyticsEventChatImpression = 'chat_impression'
export const AnalyticsEventManualChatMessageImpression = 'chat_message_impression'

export const AnalyticsEventCookieBannerAcceptAll = 'cookie_banner_accept_all'
export const AnalyticsEventCookieBannerRejectAll = 'cookie_banner_reject_all'
export const AnalyticsEventCookieBannerSaveSettings = 'cookie_banner_save_settings'

export const AnalyticsEventScreenshotButtonClick = 'screenshot_button_clicked'

export const AnalyticsEventDownloadFloatingBannerPrimaryButtonClicked = 'download_floating_banner_primary_button_clicked'

export const AnalyticsEventTopSearchBarStartToWrite = 'top_search_bar_start_to_write'
export const AnalyticsEventTopSearchBarSubmitQuery = 'top_search_bar_submit_query'

export const AnalyticsEventPlusPlanPageView = 'plus_plan_page_view'
export const AnalyticsEventDownloadLandingPageImpression = 'download_landing_page_impression'
export const AnalyticsEventPlusPlanInfoImpression = 'plus_plan_info_impression'
export const AnalyticsEventPlusPlanSubscriptionType = 'plus_plan_subscription_checkout_type'
export const AnalyticsEventPlusPlanGetPlusClicked = 'plus_plan_get_plus_click'
export const AnalyticsEventPlusPlanContactHarryClick = 'plus_plan_contact_harrt'
export const AnalyticsEventPlusPlanFAQClick = 'plus_plan_faq_click'

export const AnalyticsEventProPlanEntryFunnelHomepageImpression = 'pro_home_section_impression'
export const AnalyticsEventProPlanEntryFunnelHomepageClick = 'pro_home_section_click'
export const AnalyticsEventProPlanPageImpression = 'pro_page_impression'
export const AnalyticsEventProPlanSectionImpression = 'pro_page_section_impression'
export const AnalyticsEventProPlanPurchaseClick = 'pro_page_purchase_click'
export const AnalyticsEventProPlanFAQClick = 'pro_page_faq_click'
export const AnalyticsEventProPlanBenefitClick = 'pro_page_benefit_impression'
export const AnalyticsEventProPlanWebAppCTAClick = 'pro_web_app_cta_click'
export const AnalyticsEventProPlanDownloadWebAppImpression = 'pro_download_web_app_impression'
export const AnalyticsEventProPlanDownloadWebAppClick = 'pro_download_web_app_click'
export const AnalyticsEventProPlanDownloadSeoImpression = 'pro_download_seo_impression'
export const AnalyticsEventProPlanDownloadSeoClick = 'pro_download_seo_click'

export const AnalyticsEventProPlanMenuCTAImpression = 'pro_menu_cta_impression'
export const AnalyticsEventProPlanMenuCTAClick = 'pro_menu_cta_click'

export const AnalyticsEventSessionDuration = 'session_duration'
export const AnalyticsEventKnowPageSessionDuration = 'know_page_session_duration'

export const AnalyticsEventAdSurveyRedirectPageView = 'ad_survey_redirect_page_view'

export const AnalyticsEventPageView = 'page_view'

export const AnalyticsEventUploadAlternativeSignUpModalOpen = 'upload_alternative_sign_up_modal_open'
export const AnalyticsEventUploadAlternativeSignUpEmailSubmit = 'upload_alternative_sign_up_email_submit'

export const AnalyticsEventFacebookMessengerOpen = 'facebook_messenger_open'

export const AnalyticsEventFacebookMessengerHide = 'facebook_messenger_hide'

export const AnalyticsEventSimilarKnowsSectionImpression = 'similar_knows_section_impression'
export const AnalyticsEventSimilarKnowsButtonClick = 'similar_knows_button_click'

export const AnalyticsEventKnowImageUpload = 'know_image_upload'

export const AnalyticsEventNavbarOpenAppButtonClick = 'navbar_open_app_button_click'

export const AnalyticsEventUploadPreregisterSignUpModalOpen = 'preregister_sign_up_modal_open'
export const AnalyticsEventUploadPreregisterSignUpEmailSubmit = 'preregister_sign_up_email_submit'

export const AnalyticsEventUploadFunnelFeatureStoryImpression = 'upload_funnel_feature_story_impression'

export const AnalyticsEventFlashcardsImportPageView = 'flashcards_import_page_view'
export const AnalyticsEventFlashcardsImportButtonClick = 'flashcards_import_button_click'
export const AnalyticsEventFlashcardsImportComplete = 'flashcards_import_complete'

export const AnalyticsEventWebappConversionBannerImpression = 'webapp_conversion_banner_impression'
export const AnalyticsEventWebappConversionBannerClick = 'webapp_conversion_banner_click'

export const AnalyticsEventWebappSignupDialogSuccessfulSignUp = 'webapp_signup_dialog_successful_signup'

export const AnalyticsEventPreventWebappAccessDialogImpression = 'prevent_webapp_access_dialog_impression'
export const AnalyticsEventPreventWebappAccessRefreshSuccess = 'prevent_webapp_access_refresh_success'
export const AnalyticsEventPreventWebappAccessRefreshFailure = 'prevent_webapp_access_refresh_failure'
export const AnalyticsEventPreventWebappAccessDownloadButtonClick = 'prevent_webapp_access_download_button_click'

export const AnalyticsEventSearch = 'search'
export const AnalyticsEventSearchResultPage = 'search_results_page'
export const AnalyticsEventSearchSuggestion = 'search_suggestion_select'
export const AnalyticsEventSearchPageImpression = 'search_page_impression'

export const AnalyticsEventFlashcardSetCompleted = 'know_flashcard_set_completed'
export const AnalyticsEventFlashcardImpression = 'know_flashcard_impression'
export const AnalyticsEventFlashcardComplete = 'know_flashcard_completed'
export const AnalyticsEventFlashcardsContinueLearningClick = 'know_flashcards_continue_learning_click'
export const AnalyticsEventFlashcardsDialogOpen = 'know_flashcards_dialog_open'

export const AnalyticsEventKnowContentDuration5 = 'know_content_duration_5'
export const AnalyticsEventKnowContentDuration10 = 'know_content_duration_10'
export const AnalyticsEventKnowContentDuration15 = 'know_content_duration_15'
export const AnalyticsEventKnowContentDuration30 = 'know_content_duration_30'
export const AnalyticsEventKnowContentDuration60 = 'know_content_duration_60'
export const AnalyticsEventKnowContentDuration90 = 'know_content_duration_90'
export const AnalyticsEventKnowContentDuration120 = 'know_content_duration_120'

export const AnalyticsEventSignUpImpression = 'sign_up_impression'

export const AnalyticsEventOnboardingRegistrationInviteCodeModalOpen = 'onboarding_registration_modal_open'
export const AnalyticsEventOnboardingRegistrationInviteCode = 'onboarding_registration_invite_code'
export const AnalyticsEventOnboardingRegistrationGrade = 'onboarding_registration_grade'
export const AnalyticsEventOnboardingRegistrationSubjects = 'onboarding_registration_subjects'

export const AnalyticsEventBecomeAmbassadorClick = 'become_ambassador_click'
export const AnalyticsEventBecomeAmbassadorImpression = 'become_ambassador_impression'

export const AnalyticsEventMobileSearchBarDialogClick = 'mobile_searchbar_click'

export const AnalyticsEventPlusPlanDownloadButtonImpression = 'plus_plan_download_button_impression'
export const AnalyticsEventPlusPlanDownloadButtonClick = 'plus_plan_download_button_click'
export const AnalyticsEventPlusPlanDownloadPremiumButtonClick = 'plus_plan_download_premium_button_click'
export const AnalyticsEventPlusPlanSuccessDownloadButtonImpression = 'success_download_button_impression'
export const AnalyticsEventPlusPlanSuccessDownloadButtonClick = 'success_download_button_click'
export const AnalyticsEventPlusPlanDownloadSuccess = 'plus_plan_download_success'

export const AnalyticsEventUserSurveyBannerImpression = 'user_survey_banner_impression'
export const AnalyticsEventUserSurveyBannerClick = 'user_survey_banner_click'
export const AnalyticsEventUserSurveyDialogImpression = 'user_survey_dialog_impression'
export const AnalyticsEventUserSurveySubmit = 'user_survey_submit'
export const AnalyticsEventUserSurveyDialogClose = 'user_survey_dialog_close'

export const AnalyticsEventSearchStartTyping = 'search_start_typing'

export const AnalyticsEventInAppExperienceImpression = 'in_app_experience_impression'
export const AnalyticsEventInAppExperienceDecline = 'in_app_experience_decline'
export const AnalyticsEventInAppExperienceConversion = 'in_app_experience_conversion'

export const AnalyticsEventEnterCorrectDomainImpression = 'enter_correct_domain_impression'
export const AnalyticsEventEnterCorrectDomainDecline = 'enter_correct_domain_decline'
export const AnalyticsEventEnterCorrectDomainRedirect = 'enter_correct_domain_redirect'

export const AnalyticsEventKnowSubjectsSectionImpression = 'know_subjects_section_impression'
export const AnalyticsEventUploadAppFeaturedStoryImpression = 'app_featured_story_impression'
export const AnalyticsEventUploadAppFeaturedStoryButtonClick = 'app_featured_story_button_click'

export const AnalyticsEventSyncedKnowLoginSuccessful = 'synced_know_login_successful'
export const AnalyticsEventSyncedKnowRedirectSuccessful = 'synced_know_redirect_successful'

export const AnalyticsEventSubjectsScrollListClick = 'subjects_scroll_list_click'

export const AnalyticsEventKnowSEOSubjectClick = 'know_seo_subject_click'

export const AnalyticsEventSignupEmailBannerClick = 'signup_email_banner_click'

export const AnalyticsEventSidebarConversionBannerImpression = 'sidebar_conversion_banner_impression'
export const AnalyticsEventSidebarConversionBannerClick = 'sidebar_conversion_banner_click'

export const AnalyticsEventMobilePNGPreviewConversionBannerClick = 'mobile_png_preview_conversion_banner_click'
export const AnalyticsEventMobileSummaryPNGPreviewConversionBannerClick =
    'mobile_summary_png_preview_conversion_banner_click'

export const AnalyticsEventEmailBannerFlowEmailSubmit = 'email_banner_flow_email_submit'
export const AnalyticsEventEmailBannerFlowPasswordSubmit = 'email_banner_flow_password_submit'

export const AnalyticsEventKnowerProfileKnowClick = 'knower_profile_know_click'

export const AnalyticsEventEmailConfirmationSuccess = 'email_confirmation_success'

export const AnalyticsEventEmailConfirmationFailure = 'email_confirmation_failure'

export const AnalyticsEventWebChatRedirect = 'web_chat_redirect'
export const AnalyticsEventAiChatRedirect = 'ai_chat_redirect'

export const AnalyticsEventBlurredPagesDialogView = 'blurred_pages_dialog_view'

export const AnalyticsEventSeoConversionBannerImpression = 'seo_conversion_banner_page_impression'
export const AnalyticsEventSeoConversionBannerClick = 'seo_conversion_banner_page_click'

export const AnalyticsEventPractisingElementImpression = 'practising_element_impression'
export const AnalyticsEventPractisingElementAnswer = 'practising_element_answer'
export const AnalyticsEventPractisingElementCloseDialog = 'practising_element_close_dialog'
export const AnalyticsEventPractisingElementSignUp = 'practising_element_signup'

export const AnalyticsEventAiBuddyChatImpression = 'ai_buddy_chat_impression'
export const AnalyticsEventAiBuddyChatStartedTyping = 'ai_buddy_chat_started_typing'
export const AnalyticsEventAiBuddyChatPromptSelected = 'ai_buddy_chat_prompt_selected'
export const AnalyticsEventAiBuddyChatGoogleClick = 'ai_buddy_chat_google_click'
export const AnalyticsEventAiBuddyChatUserCreate = 'ai_buddy_chat_user_create'
export const AnalyticsEventAiBuddyChatOpened = 'ai_buddy_chat_opened'
export const AnalyticsEventAiBuddyChatClosed = 'ai_buddy_chat_closed'

export const AnalyticsEventCompanyPageContactButtonClick = 'company_page_contact_button_click'
export const AnalyticsEventCompanyPageSectionImpression = 'company_page_section_impression'

export const AnalyticsEventGtagConversionEventFailed = 'gtag_conversion_event_failed'

export const AnalyticsEventStudyGuideSubjectRedirect = 'study_guide_subject_redirect'
export const AnalyticsEventStudyGuideNextRedirect = 'study_guide_next_redirect'
export const AnalyticsEventStudyGuideMenuRedirect = 'study_guide_menu_redirect'

export const AnalyticsEventShowingPreventAccessDialogKnowPage = 'show_prevent_access_dialog_know_page'

export const AnalyticsEventStepByStepPADBannerImpression = 'step_by_step_pad_banner_impression'
export const AnalyticsEventStepByStepPADBannerConversionImpression = 'step_by_step_pad_banner_conversion_impression'
export const AnalyticsEventStepByStepPADBannerEmailClick = 'step_by_step_pad_banner_email_click'
export const AnalyticsEventStepByStepPADBannerSignUp = 'step_by_step_pad_banner_sign_up'
export const AnalyticsEventStepByStepPADBannerDownloadButtonClick = 'step_by_step_pad_banner_download_button_click'
export const AnalyticsEventStepByStepPADBannerPasswordClick = 'step_by_step_pad_banner_password_click'
export const AnalyticsEventStepByStepPADBannerRefreshClick = 'step_by_step_pad_banner_refresh_click'
export const AnalyticsEventStepByStepPADBannerLogoutClick = 'step_by_step_pad_banner_logout_click'

export const AnalyticsEventDownloadDropdownImpression = 'download_dropdown_impression'
export const AnalyticsEventDownloadDropdownStoreClick = 'download_dropdown_store_click'

export const AnalyticsEventTransactionalEmailUnsubcribed = 'transactional_email_unsubscribe'

export const AnalyticsEventAiEnhancedKnowPageSectionImpression = 'ai_enhanced_know_page_section_impression'

export const AnalyticsEventSeoFullScreenBannerView = 'seo_full_screen_banner_view'
export const AnalyticsEventSeoFullScreenBannerClose = 'seo_full_screen_banner_close'
export const AnalyticsEventSeoFullScreenBannerGoogleClick = 'seo_full_screen_banner_google_click'
export const AnalyticsEventSeoFullScreenBannerEmailClick = 'seo_full_screen_banner_email_click'

export const AnalyticsEventKnowImagePageImpression = 'know_image_page_impression'
export const AnalyticsEventKnowImagePageClick = 'know_image_page_click'
