import { GetServerSidePropsContext } from 'next'
import { parseCookies, setCookie } from 'nookies'

const ActiveExperimentsKEY = 'ACTIVE_EXPERIMENTS'

export type ActiveExperiments = Record<ExperimentKey, ExperimentVariant>

export enum ExperimentKey {
    test = 'TEST',
    mobileSignupButtonContrastEmphasis = 'MOBILE_SIGNUP_BUTTON_CONTRAST_EMPHASIS',
    showSidebarNavigationPages = 'SHOW_SIDEBAR_NAVIGATION_PAGES',
    blurInitialMobilePDFPage = 'BLUR_INITIAL_MOBILE_PDF_PAGE',
    rerunDarkThemeLayout = 'RERUN_DARK_THEME_LAYOUT',
    makeMobileThumbnailBigger = 'MAKE_MOBILE_THUMBNAIL_BIGGER',
}

export enum ExperimentVariant {
    testBaseline = 'TEST_0',
    testVariant = 'TEST_1',
    mobileSignupButtonContrastEmphasisVariant0 = 'MOBILE_SIGNUP_BUTTON_CONTRAST_EMPHASIS_VARIANT_0',
    mobileSignupButtonContrastEmphasisVariant1 = 'MOBILE_SIGNUP_BUTTON_CONTRAST_EMPHASIS_VARIANT_1',
    showSidebarNavigationPagesVariant0 = 'SHOW_SIDEBAR_NAVIGATION_PAGES_V1_VARIANT_0',
    showSidebarNavigationPagesVariant1 = 'SHOW_SIDEBAR_NAVIGATION_PAGES_V1_VARIANT_1',
    blurInitialMobilePDFPageVariant0 = 'BLUR_INITIAL_MOBILE_PDF_PAGE_VARIANT_0',
    blurInitialMobilePDFPageVariant1 = 'BLUR_INITIAL_MOBILE_PDF_PAGE_VARIANT_1',
    rerunDarkThemeLayoutVariant0 = 'RERUN_DARK_THEME_LAYOUT_VARIANT_0',
    rerunDarkThemeLayoutVariant1 = 'RERUN_DARK_THEME_LAYOUT_VARIANT_1',
    makeMobileThumbnailBiggerVariant0 = 'MAKE_MOBILE_THUMBNAIL_BIGGER_VARIANT_0',
    makeMobileThumbnailBiggerVariant1 = 'MAKE_MOBILE_THUMBNAIL_BIGGER_VARIANT_1',
}

function getRandomVariant(variants: ExperimentVariant[]): ExperimentVariant {
    return variants[Math.floor(Math.random() * variants.length)]
}

function getStoredVariant(storedVariants: string[], variants: ExperimentVariant[]): ExperimentVariant | null {
    for (const variant of variants) {
        if (storedVariants.includes(variant)) {
            return variant
        }
    }

    return null
}

function getExperimentVariant(possibleVariants: ExperimentVariant[], storedVariants: string[]): ExperimentVariant {
    const storedVariant = getStoredVariant(storedVariants, possibleVariants)
    if (storedVariant !== null) {
        return storedVariant
    } else {
        return getRandomVariant(possibleVariants)
    }
}

export function getActiveExperiments(ctx?: GetServerSidePropsContext): ActiveExperiments {
    const storedVariants = getActiveExperimentsStore(ctx)

    const activeExperiments: ActiveExperiments = {
        TEST: getExperimentVariant([ExperimentVariant.testBaseline, ExperimentVariant.testVariant], storedVariants),
        MOBILE_SIGNUP_BUTTON_CONTRAST_EMPHASIS: getExperimentVariant(
            [
                ExperimentVariant.mobileSignupButtonContrastEmphasisVariant0,
                ExperimentVariant.mobileSignupButtonContrastEmphasisVariant1,
            ],
            storedVariants
        ),
        SHOW_SIDEBAR_NAVIGATION_PAGES: getExperimentVariant(
            [ExperimentVariant.showSidebarNavigationPagesVariant0, ExperimentVariant.showSidebarNavigationPagesVariant1],
            storedVariants
        ),
        BLUR_INITIAL_MOBILE_PDF_PAGE: getExperimentVariant(
            [ExperimentVariant.blurInitialMobilePDFPageVariant0, ExperimentVariant.blurInitialMobilePDFPageVariant1],
            storedVariants
        ),
        RERUN_DARK_THEME_LAYOUT: getExperimentVariant(
            [ExperimentVariant.rerunDarkThemeLayoutVariant0, ExperimentVariant.rerunDarkThemeLayoutVariant1],
            storedVariants
        ),
        MAKE_MOBILE_THUMBNAIL_BIGGER: getExperimentVariant(
            [ExperimentVariant.makeMobileThumbnailBiggerVariant0, ExperimentVariant.makeMobileThumbnailBiggerVariant1],
            storedVariants
        ),
    }

    storeActiveExperiments(Object.values(activeExperiments), ctx)

    return activeExperiments
}

export function getActiveExperimentsVariants() {
    const activeExperiments = getActiveExperiments()
    return Object.values(activeExperiments)
}

function isValidExperiment(experiment: ExperimentVariant, possibleVariants: ExperimentVariant[]): boolean {
    return possibleVariants.includes(experiment)
}

function cachedExperimentsAreValid(cachedExperiments: ActiveExperiments | null): boolean {
    if (!cachedExperiments) return false

    const allPossibleVariants = Object.values(ExperimentVariant)
    return Object.values(cachedExperiments).every((variant) => isValidExperiment(variant, allPossibleVariants))
}

// Active Experiments
export function getActiveExperimentsStore(ctx?: GetServerSidePropsContext): string[] {
    const cookies = parseCookies(ctx)
    const item = cookies[ActiveExperimentsKEY]
    if (!item) {
        return []
    }
    return item.split(',')
}

export const storeActiveExperiments = (experiments: string[], ctx?: GetServerSidePropsContext) => {
    const stringifiedValue = experiments.join(',')
    setCookie(ctx, ActiveExperimentsKEY, stringifiedValue, {
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
    })
}
